<template>
  <q-page padding
    ><q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Master Tag</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Gerenciar" to="/campaigns" />
        <q-breadcrumbs-el label="Master Tag" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <q-card flat bordered>
      <q-card-section class="row q-col-gutter-md">
        <div class="col-6">
          <q-select v-model="model" :options="options" label="Campanha" outlined />
        </div>
        <div class="col-6">
          <q-date-input outlined label="Data de ativação" />
        </div>
      </q-card-section>
      <q-card-actions vertical align="center">
        <q-btn unelevated class="full-width" label="Buscar" color="positive" no-caps style="max-width: 500px" />
      </q-card-actions>
    </q-card>
  </q-page>
</template>

<script>
export default {
  name: 'MasterTag',
  components: {}
};
</script>
